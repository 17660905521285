(function() {
  angular.module("app", ["ngResource", "ngRoute"]).run(function($rootScope) {
    $rootScope.log = function(thing) {
      return console.log(thing);
    };
    return $rootScope.alert = function(thing) {
      return alert(thing);
    };
  });

}).call(this);

(function() {
  angular.module("app").controller("MapController", [
    "$scope", "$location", "$timeout", "MapService", "ClassesResource", "ClassesService", "$sce", "VideosResource", function($scope, $location, $timeout, MapService, ClassesResource, ClassesService, $sce, VideosResource) {
      _.defer(function() {
        return jQuery('.row').css('opacity', 0);
      });
      $scope.MapService = MapService;
      $scope.mapObj = null;
      $scope.rddcClass = "rddc_cover_logo";
      $scope.kuremaClass = "cover_logo";
      $scope.coverClass = "cover_on";
      $scope.numberOfClasses = 0;
      $scope.countries = [];
      $scope.back = false;
      $scope.classes = ClassesResource.query();
      $scope.videos = VideosResource.query();
      $scope.classes.$promise.then(function(result) {
        _.defer(function() {
          return jQuery('.row').css('opacity', 1);
        });
        $scope.numberOfClasses = $scope.classes.length;
        $scope.countries = _.uniq(_.pluck($scope.classes, 'country').map(function(c) {
          if (c[2] === '-') {
            return 'US';
          } else {
            return c;
          }
        }));
        return $scope.makeMap();
      });
      $scope.unselectRegion = function() {
        var obj;
        if ($scope.code.length) {
          obj = {};
          obj[$scope.code] = false;
          $scope.mapObj.setSelectedRegions(obj);
        }
        return $scope.code = '';
      };
      $scope.changeCode = function(code) {
        return $scope.code = code;
      };
      $scope.codeFilter = function(item) {
        if ($scope.code === '') {
          return true;
        }
        return $scope.code === item.country.slice(0, +($scope.code.length - 1) + 1 || 9e9);
      };
      $scope.makeMap = function() {
        $scope.back = false;
        $scope.code = '';
        $scope.mapObj = MapService.createMap('#map', $scope, {});
        $scope.rddcClass = "rddc_top_logo";
        $scope.kuremaClass = "top_logo";
        return $scope.coverClass = "cover_off";
      };
      $scope.to_trusted = function(html_code) {
        return $sce.trustAsHtml(html_code);
      };
      return $scope.parseId = function(url) {
        return url.slice(url.lastIndexOf('/') + 1);
      };
    }
  ]);

}).call(this);

(function() {
  angular.module("app").controller("NewsController", [
    "$scope", "$location", "$timeout", "NewsResource", "$sce", "VideosResource", function($scope, $location, $timeout, NewsResource, $sce, VideosResource) {
      _.defer(function() {
        return jQuery('.row').css('opacity', 0);
      });
      $scope.to_trusted = function(html_code) {
        return $sce.trustAsHtml(html_code);
      };
      $scope.parseId = function(url) {
        return url.slice(url.lastIndexOf('/') + 1);
      };
      $scope.news = NewsResource.query();
      return $scope.news.$promise.then(function() {
        return _.defer(function() {
          return jQuery('.row').css('opacity', 1);
        });
      });
    }
  ]);

}).call(this);

(function() {
  angular.module("app").directive("fadeInSearch", [
    "$timeout", function($timeout) {
      return function(scope, el, attr) {
        var delay;
        delay = function(ms, func) {
          return $timeout(func, ms);
        };
        return delay(150, (function() {
          return _.defer(function() {
            return el.css('opacity', 1);
          });
        }));
      };
    }
  ]);

}).call(this);

(function() {
  angular.module("app").directive("youtube", [
    "$timeout", function($timeout) {
      return function(scope, el, attr) {
        return el.YouTubePopup();
      };
    }
  ]);

}).call(this);

(function() {
  angular.module("app").factory("BookResource", function($q, $resource) {
    return $resource('/books');
  });

}).call(this);

(function() {
  angular.module("app").factory("ClassesResource", function($q, $resource) {
    return $resource('http://rebeccadavisdance.com/wp-admin/admin-ajax.php?action=get_kurema_classes');
  });

  angular.module("app").factory("ClassesRandomResource", function($q, $resource) {
    return $resource('/randomclasses');
  });

}).call(this);

(function() {
  angular.module("app").factory("NewsResource", function($q, $resource) {
    return $resource('http://rebeccadavisdance.com/wp-admin/admin-ajax.php?action=get_kurema_news');
  });

}).call(this);

(function() {
  angular.module("app").factory("VideosResource", function($q, $resource) {
    return $resource('http://rebeccadavisdance.com/wp-admin/admin-ajax.php?action=get_kurema_videos');
  });

}).call(this);

(function() {
  angular.module("app").config(function($routeProvider, $locationProvider) {
    $locationProvider.html5Mode(false);
    $routeProvider.when('/login', {
      templateUrl: 'login.html',
      controller: 'LoginController'
    });
    $routeProvider.when('/map', {
      templateUrl: 'map.html',
      controller: 'MapController'
    });
    $routeProvider.when('/', {
      templateUrl: 'news.html',
      controller: 'NewsController'
    });
    return $routeProvider.otherwise({
      redirectTo: '/'
    });
  });

}).call(this);

(function() {
  angular.module("app").factory("BookService", function($q, $http) {
    var getBooks;
    getBooks = function() {
      return $http.get('/books');
    };
    return {
      getBooks: getBooks
    };
  });

}).call(this);

(function() {
  angular.module("app").service("ClassesService", function() {
    return {
      classesInCountry: function(classes, code) {
        return _.filter(classes, function(c) {
          if (code === '') {
            return true;
          }
          return code === c.country.slice(0, +(code.length - 1) + 1 || 9e9);
        });
      },
      classesPerCountry: function(classes) {
        return _.reduce(classes, function(memo, c) {
          if (memo.hasOwnProperty(c.country)) {
            memo[c.country] += 1;
          } else {
            memo[c.country] = 1;
          }
          if (c.country.slice(0, 3) === 'US-') {
            if (memo.hasOwnProperty('US')) {
              memo['US'] += 1;
            } else {
              memo['US'] = 1;
            }
          }
          return memo;
        }, {});
      },
      addRandomRegion: function(c, regions) {
        c.country = _.shuffle(this.generateAllRegions())[0];
        return c;
      },
      generateRandomData: function(classes) {
        var c, _i, _len, _results;
        _results = [];
        for (_i = 0, _len = classes.length; _i < _len; _i++) {
          c = classes[_i];
          _results.push(this.addRandomRegion(c));
        }
        return _results;
      },
      generateAllRegions: function() {
        var regionsUS, regionsW;
        regionsW = _.shuffle(_.keys(_.omit(jvm.WorldMap.maps.world_mill_en.paths, 'US'))).slice(0, 41);
        regionsUS = _.shuffle(_.keys(jvm.WorldMap.maps.us_aea_en.paths)).slice(0, 11);
        return _.union(regionsW, regionsUS);
      }
    };
  });

}).call(this);

(function() {
  angular.module("app").factory("MapService", [
    "ClassesService", function(ClassesService) {
      var createMap, defaults, destroyMap, getSeries, mapActive, mapContainer, mapUS, mapWorld, print, regionName, scope;
      mapActive = false;
      mapWorld = new jvm.WorldMap({
        map: 'world_mill_en',
        container: $('<div></div>')
      });
      mapUS = new jvm.WorldMap({
        map: 'us_aea_en',
        container: $('<div></div>')
      });
      regionName = function(code) {
        try {
          return mapWorld.getRegionName(code);
        } catch (_error) {
          return 'US - ' + mapUS.getRegionName(code);
        }
      };
      mapContainer = '<div class="mapContainer"></div>';
      scope = null;
      defaults = {
        map: 'world_mill_en',
        regionsSelectable: true,
        regionsSelectableOne: true,
        backgroundColor: 'whitesmoke',
        onRegionLabelShow: function(e, label, code) {
          var country, number, text;
          country = label.html();
          number = ClassesService.classesInCountry(scope.classes, code).length;
          text = number > 1 ? "" + number + " classes here" : number === 1 ? "1 class here" : "no classes here";
          return label.html("" + country + " - " + text);
        },
        onRegionSelected: function(e, code) {
          scope.code = code;
          if (code === 'US') {
            createMap('#map', scope, {
              map: 'us_aea_en',
              onRegionSelected: function(e, code) {
                scope.code = code;
                return scope.$apply();
              }
            });
            scope.back = true;
            return scope.$apply();
          } else {
            if (!scope.$$phase) {
              return scope.$apply();
            }
          }
        }
      };
      destroyMap = function(element) {
        $(element).remove();
        return scope = null;
      };
      createMap = function(element, scp, opts) {
        if (opts == null) {
          opts = {};
        }
        if (mapActive) {
          mapActive.remove();
        }
        scope = scp;
        opts = _.defaults(opts, getSeries());
        opts = _.defaults(opts, defaults);
        opts.container = $(element);
        mapActive = new jvm.WorldMap(opts);
        print(mapActive);
        return mapActive;
      };
      print = function(m) {
        var arr, p;
        console.log(m);
        arr = [];
        for (p in m.regions) {
          arr.push({
            abbr: p,
            country: m.regions[p].config.name
          });
        }
        arr.sort(function(a, b) {
          if (a.abbr < b.abbr) {
            return -1;
          } else {
            return 1;
          }
        });
        return arr.map(function(a) {
          return console.log(a.abbr, a.country);
        });
      };
      getSeries = function() {
        return {
          series: {
            regions: [
              {
                values: ClassesService.classesPerCountry(scope != null ? scope.classes : void 0),
                scale: ['#87d86e', '#238306'],
                normalizeFunction: 'polynomial'
              }
            ]
          }
        };
      };
      return {
        createMap: createMap,
        regionName: regionName
      };
    }
  ]);

}).call(this);
