angular.module("app").run(["$templateCache", function($templateCache) {

  $templateCache.put("books.html",
    "<!-- With $resource -->\n" +
    "\n" +
    "<ul class=\"books\">\n" +
    "  <li class=\"panel\" ng-repeat=\"book in books\">\n" +
    "    {{ book.title }} by {{ book.author }}\n" +
    "  </li>\n" +
    "</ul>\n" +
    "\n" +
    "<!-- With $http\n" +
    "\n" +
    "Note the extra 'books.data', which is needed because $resource does fancy promise resolution\n" +
    "when attached to a controller scope\n" +
    "\n" +
    "<ul class=\"books\">\n" +
    "  <li class=\"panel\" ng-repeat=\"book in books.data\">\n" +
    "    {{ book.title }} by {{ book.author }}\n" +
    "  </li>\n" +
    "</ul>\n" +
    " -->\n"
  );

  $templateCache.put("login.html",
    "<div id=\"login\" class=\"row\">\n" +
    "  <div class=\"large-6 large-offset-3\">\n" +
    "    <form ng-submit=\"login()\">\n" +
    "      <fieldset class=\"radius\">\n" +
    "        <div class=\"row\">\n" +
    "          <div class=\"large-6 columns\">\n" +
    "            <input type=\"text\" name=\"username\" placeholder=\"username\" ng-model=\"credentials.username\" required/>\n" +
    "          </div>\n" +
    "            <div class=\"large-6 columns\">\n" +
    "              <input type=\"password\" name=\"password\" placeholder=\"password\" ng-model=\"credentials.password\" required/>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"row\">\n" +
    "          <div class=\"large-12 columns\">\n" +
    "            <button id=\"log-in\" type=\"submit\" class=\"button large expand radius\">Log In</button>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </fieldset>\n" +
    "    </form>\n" +
    "  </div>\n" +
    "</div>\n"
  );

  $templateCache.put("map.html",
    "<img id=\"logo\" src=\"img/kurema_logo_no_text.jpg\" class=\"logo {{kuremaClass}}\">\n" +
    "<img id=\"rddcLogo\" src=\"img/rddcLogo.jpg\" class=\"logo {{rddcClass}}\">\n" +
    "<div class=\"cover {{coverClass}}\">\n" +
    "</div>\n" +
    "<div class=\"row well\">\n" +
    "<div class=\"btn-group col-md-12\">\n" +
    "<h4>{{numberOfClasses}} classes in {{countries.length}} countries</h4>\n" +
    "<a href=\"#/\">\n" +
    "<button type=\"button\" style=\"float: left; margin-right: 10px;\" class=\"btn btn-default hidden-sm hidden-xs\">Kurema News</button>\n" +
    "</a>\n" +
    "<button type=\"button\" style=\"float:right;\" class=\"btn btn-default hidden-sm hidden-xs\" ng-show=back ng-click=makeMap()>Back to World</button>  \n" +
    "<button type=\"button\" style=\"float:right;\" class=\"btn btn-default hidden-sm hidden-xs\" ng-show = code.length ng-disabled=!code.length ng-click=unselectRegion()>All Countries</button>\n" +
    "<button type=\"button\" style=\"margin-right: 10px;\" class=\"btn btn-default hidden-sm hidden-xs videoButton\" ng-repeat=\"video in videos\" rel=\"{{parseId(video.link)}}\"><img width=\"20\" height=\"20\" src=\"/img/youtube_icon.gif\"> {{video.title}}\n" +
    "<script>jQuery(\".videoButton\").YouTubePopup()</script>\n" +
    "</button>  \n" +
    "</div>\n" +
    "</div>\n" +
    "<div class=\"listInterface well row hidden-md hidden-lg\">\n" +
    "<div id=\"countryList\" class=\"col-md-3 hidden-md hidden-lg\">\n" +
    "<div class=\"tableWell country\" ng-click=\"changeCode('')\"><h4>All Classes</h4></div>\n" +
    "<div class=\"tableWell country\" ng-click=\"changeCode(country)\" ng-repeat=\"country in countries\"><h4>{{MapService.regionName(country)}}</h4></div>\n" +
    "\n" +
    "\n" +
    "<div class=\"tableContainer col-md-8 hidden-md hidden-lg\">\n" +
    "  <div class=\"tableWell hidden-md hidden-lg\" ng-repeat=\"class in classes | filter: codeFilter\">\n" +
    "    <h4>{{MapService.regionName(class.country)}}</h4>\n" +
    "    <h4>{{class.title}}</h4>\n" +
    "    <div ng-bind-html=\"to_trusted(class.description)\"></div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "</div>\n" +
    "</div>\n" +
    "<div class=\"mapInterface well row\">\n" +
    "<div id=\"map\" class=\"col-md-8 hidden-sm hidden-xs\">\n" +
    "</div>\n" +
    "<div class=\"tableContainer col-md-4\">\n" +
    "    <div class=\"tableWell hidden-sm hidden-xs\" ng-repeat=\"class in classes | filter: codeFilter\">\n" +
    "      <h4>{{MapService.regionName(class.country)}}</h4>\n" +
    "      <h4>{{class.title}}</h4>\n" +
    "      <div ng-bind-html=\"to_trusted(class.description)\"></div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"row well\">\n" +
    "<div class=\"col-md-5\"><img src=\"img/kurema_logo_no_text.jpg\" style=\"width: 80%;\"></div>\n" +
    "<div class=\"col-md-7\">\n" +
    "  <h2>WHY?</h2>\n" +
    "  <p>For 24 hours on April 26, <a target=\"_blank\" href=\"http://rebeccadavisdance.com\">RDDC</a> is organizing a global dance event to recognize, remember and commemorate the 20th anniversary of the Tutsi Genocide in Rwanda.  On this day, 100 dance teachers from around the world will hold a minute of silence in their dance class and encourage students to send a message to the youth dancing in Rwanda today.</p>\n" +
    "\n" +
    "</div>\n" +
    "</div>\n" +
    "<div class=\"row well\">\n" +
    "<div class=\"col-md-5\"><img src=\"img/ERIC_small.jpg\" style=\"width: 80%;\"></div>\n" +
    "<div class=\"col-md-7\">\n" +
    "  <h2>LEARN</h2>\n" +
    "  <p>By visiting this site and <a target=\"_blank\" href=\"http://facebook.com/rebeccadavisdance\">RDDC</a> Facebook all through the month of April, you can learn more about the history of Rwanda and the amazing progress it has made since 1994.</p>\n" +
    "\n" +
    "</div>\n" +
    "</div>\n" +
    "<div class=\"row well\">\n" +
    "<div class=\"col-md-5\"><img src=\"img/it_class.jpg\" style=\"width: 80%;\"></div>\n" +
    "<div class=\"col-md-7\">\n" +
    "  <h2>SHARE on SOCIAL MEDIA</h2>\n" +
    "  <p>Keep checking <a target=\"_blank\" href=\"http://facebook.com/rebeccadavisdance\">RDDC Facebook</a> and <a target=\"_blank\" href=\"http://twitter.com/RDDanceCo\">Twitter</a> to see photos and videos from the classes being taught all around the world on April 26.</p>\n" +
    "\n" +
    "</div>\n" +
    "</div>\n" +
    "<div class=\"row well\">\n" +
    "<div class=\"col-md-5\"><img src=\"img/cjumps.jpg\" style=\"width: 80%;\"></div>\n" +
    "<div class=\"col-md-7\">\n" +
    "<h2>JOIN</h2>\n" +
    "  <p>Are you a teacher? If you would like to teach a class, please email <a target=\"_blank\" href=\"mailto:caitlyn@rebeccadavisdance.com\">caitlyn@rebeccadavisdance.com</a> for registration.</p>\n" +
    "\n" +
    "  <p>Are you a student? We will publish a list of classes soon. Join us on April 26!</p>\n" +
    "\n" +
    "</div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"row well\" style=\"text-align:center;\">\n" +
    "\n" +
    "<h2>QUESTIONS</h2>\n" +
    "<p>Caitlyn Casson: <a target=\"_blank\" href=\"mailto:caitlyn@rebeccadavisdance.com\">caitlyn@rebeccadavisdance.com</a></p>\n" +
    "<p>Rebecca Davis: <a target=\"_blank\" href=\"davis@rebeccadavisdance.com\">davis@rebeccadavisdance.com</a></p>\n" +
    "\n" +
    "\n" +
    "</div>\n" +
    "<div class=\"row well\">\n" +
    "\n" +
    "</div>\n" +
    "\n" +
    "\n" +
    "\n"
  );

  $templateCache.put("news.html",
    "<img id=\"logo\" src=\"img/kurema_logo_no_text.jpg\" class=\"logo top_logo\">\n" +
    "<img id=\"rddcLogo\" src=\"img/rddcLogo.jpg\" class=\"logo rddc_top_logo\">\n" +
    "<div class=\"row well\">\n" +
    "<div class=\"btn-group col-md-12\">\n" +
    "<a href=\"#/map\">\n" +
    "<button type=\"button\" class=\"btn btn-default hidden-sm hidden-xs\">Map of Kurema Classes</button>\n" +
    "</a>\n" +
    "<input type=\"text\" class=\"form-control\" id=\"searchField\" placeholder=\"Search\" ng-model=\"searchText\">\n" +
    "</div>\n" +
    "</div>\n" +
    "<div class=\"row well\" ng-repeat=\"item in news | filter:searchText | orderBy:'-order'\" fade-in-search>\n" +
    "\t<div class=\"col-md-4\" ng-if=\"item.video\"><img class=\"youtube newsImg\" youtube id=\"{{parseId(item.video)}}\" rel=\"{{parseId(item.video)}}\" src=\"http://img.youtube.com/vi/{{parseId(item.video)}}/hqdefault.jpg\">\n" +
    "\t\t<img class=\"youtube-overlay\" youtube src=\"http://onlinehelp.avs4you.com/images/VideoEditor4/PlayButton.png\" rel=\"{{parseId(item.video)}}\">\n" +
    "\t</div>\n" +
    "\t<div class=\"col-md-4\" ng-if=\"!item.video\"><img src=\"{{item.feature}}\" class=\"newsImg\"></div>\n" +
    "\t<div class=\"cold-md-8\" ng-bind-html=\"to_trusted('<h3>' + item.title + '</h3>' + item.story)\"></div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"row well\">\n" +
    "<div class=\"col-md-5\"><img src=\"img/kurema_logo_no_text.jpg\" style=\"width: 80%;\"></div>\n" +
    "<div class=\"col-md-7\">\n" +
    "  <h2>WHY?</h2>\n" +
    "  <p>For 24 hours on April 26, <a target=\"_blank\" href=\"http://rebeccadavisdance.com\">RDDC</a> is organizing a global dance event to recognize, remember and commemorate the 20th anniversary of the Tutsi Genocide in Rwanda.  On this day, 100 dance teachers from around the world will hold a minute of silence in their dance class and encourage students to send a message to the youth dancing in Rwanda today.</p>\n" +
    "\n" +
    "</div>\n" +
    "</div>\n" +
    "<div class=\"row well\">\n" +
    "<div class=\"col-md-5\"><img src=\"img/ERIC_small.jpg\" style=\"width: 80%;\"></div>\n" +
    "<div class=\"col-md-7\">\n" +
    "  <h2>LEARN</h2>\n" +
    "  <p>By visiting this site and <a target=\"_blank\" href=\"http://facebook.com/rebeccadavisdance\">RDDC</a> Facebook all through the month of April, you can learn more about the history of Rwanda and the amazing progress it has made since 1994.</p>\n" +
    "\n" +
    "</div>\n" +
    "</div>\n" +
    "<div class=\"row well\">\n" +
    "<div class=\"col-md-5\"><img src=\"img/it_class.jpg\" style=\"width: 80%;\"></div>\n" +
    "<div class=\"col-md-7\">\n" +
    "  <h2>SHARE on SOCIAL MEDIA</h2>\n" +
    "  <p>Keep checking <a target=\"_blank\" href=\"http://facebook.com/rebeccadavisdance\">RDDC Facebook</a> and <a target=\"_blank\" href=\"http://twitter.com/RDDanceCo\">Twitter</a> to see photos and videos from the classes being taught all around the world on April 26.</p>\n" +
    "\n" +
    "</div>\n" +
    "</div>\n" +
    "<div class=\"row well\">\n" +
    "<div class=\"col-md-5\"><img src=\"img/cjumps.jpg\" style=\"width: 80%;\"></div>\n" +
    "<div class=\"col-md-7\">\n" +
    "<h2>JOIN</h2>\n" +
    "  <p>Are you a teacher? If you would like to teach a class, please email <a target=\"_blank\" href=\"mailto:caitlyn@rebeccadavisdance.com\">caitlyn@rebeccadavisdance.com</a> for registration.</p>\n" +
    "\n" +
    "  <p>Are you a student? We will publish a list of classes soon. Join us on April 26!</p>\n" +
    "\n" +
    "</div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"row well\" style=\"text-align:center;\">\n" +
    "\n" +
    "<h2>QUESTIONS</h2>\n" +
    "<p>Caitlyn Casson: <a target=\"_blank\" href=\"mailto:caitlyn@rebeccadavisdance.com\">caitlyn@rebeccadavisdance.com</a></p>\n" +
    "<p>Rebecca Davis: <a target=\"_blank\" href=\"davis@rebeccadavisdance.com\">davis@rebeccadavisdance.com</a></p>\n" +
    "\n" +
    "\n" +
    "</div>\n" +
    "<div class=\"row well\">\n" +
    "\n" +
    "</div>\n"
  );

}]);
